.google-maps {
    width: 45%;
    height: 58%;
    position: absolute;
    margin-left: 32%;
}


#proteinchoice {
    float: right;
}

#vege-choice {
    float: right;
}

#contact-info {
    margin-right: 15%
}

@media(max-width: 767px){
    #proteinchoice {
        float: left;
    }

    #vege-choice {
        float: left;
    }
    .google-maps {
        width: 85%;
        height: 35%;
        margin-left: 8%;
    }

    #contact-info {
        text-align: center;
        margin-right: 0%;
        margin-top: 75%;
    }
 }
